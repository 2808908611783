import { NerdNavigationBar } from "@nerdjs/nerd-ui";
import { ReactElement } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RouterConfig } from "../config/routerConfig";

/**
 *
 * @component
 * @example
 * <MainDrawer/>
 * @returns {ReactElement | null} MainDrawer component
 */
export function MainMenu(): ReactElement | null {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const navigate = useNavigate();

  const leftItems = [
    {
      title: "Pipelines",
      action: () => {
        navigate(RouterConfig.pipelines);
      },
      children: [],
      startDecorator: <i className="fa-regular fa-arrow-progress"></i>,
      selected: pathnames[0] === RouterConfig.pipelines.slice(1),
    },
    {
      title: "Autopilot",
      children: [
        {
          title: "Hooks",
          startDecorator: <i className="fa-regular fa-fishing-rod"></i>,
          selected: false,
          action: () => {
            navigate(RouterConfig.hooks);
          },
        },
        {
          title: "Actions",
          startDecorator: <i className="fa-solid fa-hand-back-point-up"></i>,
          selected: false,
          action: () => {
            navigate(RouterConfig.actions);
          },
        },
        {
          title: "Configurations",
          startDecorator: <i className="fa-solid fa-gear"></i>,
          selected: false,
          action: () => {
            navigate(RouterConfig.configurations);
          },
        },
        {
          title: "Events",
          startDecorator: <i className="fa-solid fa-wave-pulse"></i>,
          selected: false,
          action: () => {
            navigate(RouterConfig.events);
          },
        },
      ],
      startDecorator: <i className="fa-solid fa-robot"></i>,
      selected: false,
    },
    {
      title: "Muse",
      children: [
        {
          title: "Families",
          startDecorator: <i className="fa-regular fa-layer-group"></i>,
          selected: false,
          action: () => {
            navigate(RouterConfig.families);
          },
        },
        {
          title: "Sections",
          startDecorator: <i className="fa-solid fa-users-line"></i>,
          selected: false,
          action: () => {
            navigate(RouterConfig.sections);
          },
        },
        {
          title: "Instruments",
          startDecorator: <i className="fa-solid fa-trumpet"></i>,
          selected: pathnames[0] === RouterConfig.instruments.slice(1),
          action: () => {
            navigate(RouterConfig.instruments);
          },
        },
        {
          title: "Roles",
          startDecorator: <i className="fa-solid fa-hat-cowboy"></i>,
          selected: false,
          action: () => {
            navigate(RouterConfig.roles);
          },
        },
      ],
      startDecorator: <i className="fa-solid fa-music"></i>,
      selected: false,
    },
    {
      title: "Mobile App",
      children: [
        {
          title: "Users",
          startDecorator: <i className="fa-solid fa-users"></i>,
          selected: false,
        },
      ],
      startDecorator: <i className="fa-regular fa-mobile"></i>,
      selected: false,
    },
    {
      title: "Package Config",
      children: [
        {
          title: "Musician Fields",
          startDecorator: <i className="fa-regular fa-pen-field"></i>,
          selected: false,
          action: () => {
            navigate(RouterConfig.noteTypes);
          },
        },
      ],
      startDecorator: <i className="fa-solid fa-box-open"></i>,
      selected: false,
    },
    {
      title: "Dashboard",
      action: () => {
        navigate(RouterConfig.pipelines);
      },
      children: [],
      startDecorator: <i className="fa-regular fa-gauge-high"></i>,
      selected: false,
    },
  ];

  return (
    <NerdNavigationBar
      navigationBarIndex={1}
      chipVariant="plain"
      selectedChipVariant="solid"
      rightItems={[]}
      leftItems={leftItems}
    />
  );
}
