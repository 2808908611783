import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { useGetCurrentUserQuery } from "@nerdjs/account-kit";
import React, { PropsWithChildren, ReactElement } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { RouterConfig } from "./hooks/config/routerConfig";
import NotFoundRoute from "./routes/notFoundRoute";
import { PipelinePage } from "./routes/pipeline";
import PipelinesPage from "./routes/pipelines";
import InstrumentsPage from "./routes/instruments";
import FamiliesPage from "./routes/families";
import SectionsPage from "./routes/sections";
import HooksPage from "./routes/hooks";
import EventsPage from "./routes/events";
import ConfigurationsPage from "./routes/configurations";
import ActionsPage from "./routes/actions";
import { AppConfig } from "./environement";
import NoteTypesPage from "./routes/noteTypes";
import RolesPage from "./routes/roles";

const BoxWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "hideMarginTop",
})<{ hideMarginTop?: boolean }>(({ theme, hideMarginTop }) => ({
  marginTop: 96,
  background:
    theme.palette.mode !== "light" ? "rgba(0,0,0,0.9)" : "rgba(255,255,255,1)",
  backdropFilter: "blur(10px)",
  flexGrow: 1,
  display: "flex",
}));

/**
 *
 * @returns {React.ReactElement} the router
 */
export function AppRouter(): React.ReactElement {
  const { data: currentUser } = useGetCurrentUserQuery();
  return (
    <RequireAuth>
      <Routes>
        <Route
          path={RouterConfig.root}
          element={<Navigate to={RouterConfig.pipelines} />}
        ></Route>
        <Route
          path={RouterConfig.families}
          element={
            <BoxWrapper>
              <FamiliesPage />
            </BoxWrapper>
          }
        ></Route>
        <Route
          path={RouterConfig.roles}
          element={
            <BoxWrapper>
              <RolesPage />
            </BoxWrapper>
          }
        ></Route>
        <Route
          path={RouterConfig.sections}
          element={
            <BoxWrapper>
              <SectionsPage />
            </BoxWrapper>
          }
        ></Route>
        <Route
          path={RouterConfig.hooks}
          element={
            <BoxWrapper>
              <HooksPage />
            </BoxWrapper>
          }
        ></Route>
        <Route
          path={RouterConfig.events}
          element={
            <BoxWrapper>
              <EventsPage />
            </BoxWrapper>
          }
        ></Route>
        <Route
          path={RouterConfig.configurations}
          element={
            <BoxWrapper>
              <ConfigurationsPage />
            </BoxWrapper>
          }
        ></Route>
        <Route
          path={RouterConfig.actions}
          element={
            <BoxWrapper>
              <ActionsPage />
            </BoxWrapper>
          }
        ></Route>
        <Route
          path={RouterConfig.instruments}
          element={
            <BoxWrapper>
              <InstrumentsPage />
            </BoxWrapper>
          }
        ></Route>
        <Route
          path={RouterConfig.noteTypes}
          element={
            <BoxWrapper>
              <NoteTypesPage />
            </BoxWrapper>
          }
        ></Route>
        <Route
          path={RouterConfig.pipelines}
          element={
            <BoxWrapper>
              <PipelinesPage />
            </BoxWrapper>
          }
        >
          <Route path={":id"} element={<PipelinePage />} />
        </Route>
        <Route
          path={"*"}
          element={
            <BoxWrapper hideMarginTop={currentUser ? false : true}>
              <NotFoundRoute />
            </BoxWrapper>
          }
        />
      </Routes>
    </RequireAuth>
  );
}

/**
 * Route wrapper that redirects logged off users to the signin route
 *
 * @param {PropsWithChildren} props props
 * @returns {ReactElement | null} Return RequireAuth component
 */
function RequireAuth(props: PropsWithChildren): ReactElement | null {
  const { data: currentUser } = useGetCurrentUserQuery();

  if (!currentUser) {
    window.location.replace(AppConfig.front["401Redirect"]);
    return null;
  } else {
    return <>{props.children}</>;
  }
}
