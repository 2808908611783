import { Button, Input, Typography } from "@mui/joy";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SectionRole_Entity } from "../../entities/sectionRole";
import {
  formOpenSelector,
  selectedRoleIDSelector,
  setFormOpen,
  setSelectedRoleID,
} from "../../reducers/rhapsody";
import {
  useCreateSectionRoleMutation,
  useDeleteSectionRoleMutation,
  useUpdateSectionRoleMutation,
} from "../../redux/sectionRole/sectionRoleEndpoints";
import { useSectionRole } from "../../redux/sectionRole/sectionRoleHooks";
import DialogClose from "../dialogClose/dialogClose";

/**
 *
 * @returns {ReactElement} SectionRoleForm page
 */
export function SectionRoleForm() {
  const open = useSelector(formOpenSelector("role"));
  const dispatch = useDispatch();
  const selectedSectionRoleID = useSelector(selectedRoleIDSelector);
  const { sectionRole } = useSectionRole(selectedSectionRoleID ?? 0);
  const [createSectionRole, { isLoading: l1 }] = useCreateSectionRoleMutation();
  const [deleteSectionRole, { isLoading: l3 }] = useDeleteSectionRoleMutation();
  const [updateSectionRole, { isLoading: l2 }] = useUpdateSectionRoleMutation();
  const [_sectionRole, setSectionRole] = useState<Partial<SectionRole_Entity>>({
    name: "",
  });

  useEffect(() => {
    if (sectionRole?.id) setSectionRole(sectionRole);
  }, [sectionRole]);

  const onClose = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "role" }));
    dispatch(setSelectedRoleID(null));
  };

  const save = () => {
    if (_sectionRole.id) {
      updateSectionRole({ id: _sectionRole.id, body: _sectionRole });
    } else {
      createSectionRole(_sectionRole);
    }
    onClose();
  };

  return (
    <DialogClose open={open} onClose={onClose}>
      <DialogTitle>
        <Typography
          level="title-lg"
          startDecorator={<i className="fa-regular fa-layer-group"></i>}
        >
          {_sectionRole?.name ? _sectionRole?.name : "New Role"}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
        <Input
          value={_sectionRole.name}
          onChange={(e) =>
            setSectionRole((p) => ({ ...p, name: e.target.value }))
          }
          autoFocus
          startDecorator="Name:"
          sx={{ boxShadow: "none" }}
        />
        <Input
          value={_sectionRole.position}
          onChange={(e) =>
            setSectionRole((p) => ({
              ...p,
              position: parseInt(e.target.value),
            }))
          }
          startDecorator="Position:"
          type="nu"
          sx={{ boxShadow: "none" }}
        />
        <Input
          value={`${(_sectionRole.defaultScale ?? 0) / 1000}`}
          endDecorator="%"
          onChange={(e) =>
            setSectionRole((p) => ({
              ...p,
              defaultScale: parseInt(e.target.value) * 1000,
            }))
          }
          disabled={sectionRole?.name === "-"}
          startDecorator="Default Scale:"
          sx={{ boxShadow: "none" }}
        />
      </DialogContent>
      <DialogActions>
        {selectedSectionRoleID ? (
          <Button
            variant="soft"
            color="danger"
            onClick={() => {
              deleteSectionRole(selectedSectionRoleID);
              onClose();
            }}
          >
            Delete
          </Button>
        ) : (
          []
        )}
        <Button variant="soft" color="neutral" onClick={onClose}>
          Close
        </Button>
        <Button loading={l1 || l2} onClick={save} disabled={!_sectionRole.name}>
          {selectedSectionRoleID ? "Save" : "Create"}
        </Button>
      </DialogActions>
    </DialogClose>
  );
}
