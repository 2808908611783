import { Box } from "@mui/material";
import {
  DataGridPremium,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-premium";
import { useDispatch } from "react-redux";
import { setFormOpen, setSelectedRoleID } from "../../reducers/rhapsody";
import { useSectionRoles } from "../../redux/sectionRole/sectionRoleHooks";
import Id from "../id/id";

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
});

/**
 * Family
 *
 * @returns {null} Family
 */
export default function Roles() {
  const { sectionRoles } = useSectionRoles();
  const dispatch = useDispatch();

  return (
    <DataGridPremium
      rows={sectionRoles ?? []}
      density="compact"
      columns={[
        {
          field: "id",
          headerName: "ID",
          type: "number",
          width: 60,
          renderCell: (p) => {
            if (p.id === -1) return <Box />;
            return (
              <Id
                startDecorator={<i className="fa-regular fa-layer-group"></i>}
              >
                {p.id}
              </Id>
            );
          },
        },
        { field: "name", headerName: "Name", flex: 1 },
        { field: "position", headerName: "Position", flex: 1 },
        {
          field: "defaultScale",
          headerName: "Default Scale",
          flex: 1,
          valueFormatter(e) {
            return `${(e.value / 1000).toFixed(2)}%`;
          },
        },
      ]}
      slots={{ toolbar: QuickSearchToolbar }}
      onRowClick={(e) => {
        dispatch(setSelectedRoleID(e.row.id));
        dispatch(setFormOpen({ isOpen: true, formID: "role" }));
      }}
    />
  );
}

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        display: "flex",
      }}
    >
      <GridToolbarQuickFilter
        variant="outlined"
        size="small"
        sx={{ flex: 1 }}
        fullWidth
      />
    </Box>
  );
}
