export const RouterConfig = {
  root: "/",
  pipelines: "/pipelines",
  instruments: "/instruments",
  noteTypes: "/note-types",
  sections: "/sections",
  families: "/families",
  hooks: "/hooks",
  roles: "/roles",
  actions: "/actions",
  events: "/events",
  configurations: "/configurations",
};
