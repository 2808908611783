import { nerdNetworkBaseQuery } from "@nerdjs/nerd-network";
import { createApi } from "@reduxjs/toolkit/query/react";

export const rhapsodyApi = createApi({
  reducerPath: "rhapsodyApi",
  baseQuery: (args, api) =>
    nerdNetworkBaseQuery(args, api, { service: "rhapsody" }),
  endpoints: () => ({}),
  tagTypes: ["eventStatuses", "noteTypes", "sectionRoles"],
});
