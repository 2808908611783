/**
 * SectionRole_Entity type guard.
 *
 * @param {any} sectionRoleJson SectionRole object from API
 * @returns {boolean} Return true if type is SectionRole_Entity
 */

export default class SectionRole_Entity {
  constructor(sectionRoleJson: SectionRole_Entity) {
    try {
      this.id = sectionRoleJson.id;
      this.name = sectionRoleJson.name;
      this.position = sectionRoleJson.position;
      this.defaultScale = sectionRoleJson.defaultScale;
    } catch (e) {
      throw new Error(
        "Failed to create new instance of ${ Channel_Entity.name }: ${e}"
      );
    }
  }

  id?: number;
  name: string;
  position: number;
  defaultScale: number;
}
